import React from 'react'
import './estilos.css';


function NoHayProps() {
    return (
        <div className='cont-noHayProps'>
            <div className='cont-texto-noProps'>
                <h1>No hay propiedades para mostrar</h1>
            </div>
        </div>
    )
}

export default NoHayProps