import React from 'react'
import './estilos.css';


function Nosotros() {
  return (
    <div className='cont-principal-nosotros'>
      <h1>Sobre Nosotros</h1>
      <div className='cont-nosotros'>
                
      </div>
    </div>
  )
}

export default Nosotros