import React from 'react';
import './estilos.css';
import FormularioQuieroVender from '../../components/FormularioQuieroVender';

function QuieroVenderPage() {
    return (
        <div className='cont-vender-page'>
            <FormularioQuieroVender/>
        </div>
    )
}

export default QuieroVenderPage